//https://github.com/mourner/dead-simple-grid
$c-grid-gap: 5px;

.c-grid {
  @for $i from 1 through 16 {
    &.is-grid-#{$i} {
      .c-grid--col {
        width: (100%/$i);
      }
    }
  }

  .c-grid--row {

    &:before, &:after {
      content: "";
      display: table;
    }

    &:after {
      clear: both;
    }

    .c-grid--row {
      margin: 0 -$c-grid-gap;
    }
  }

  .c-grid--col {
    float: left;
    box-sizing: border-box;
    padding-left: $c-grid-gap;

    &:last-of-type {
      padding-right: $c-grid-gap;
    }
  }
}