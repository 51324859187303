$l-header-background: #4CB141;

.l-header {
  position: relative;
  z-index: $z-5;

  background: $l-header-background;
  box-shadow: 0 1px 2px 0px rgba(0, 0, 0, 0.4);

  // Reset bootstrap styles
  min-height: $header-height;
  margin-bottom: 0;
  border: none;
  border-radius: 0;

  .navbar-toggle {
    border-color: #fff;

    .icon-bar {
      background: #fff;
    }
  }

  .navbar-collapse {
    padding: 0;

    &.in {
      overflow: visible;
      min-width: 320px;
    }
  }

  @media (min-width: $grid-float-breakpoint) {
    background: $l-header-background url("/img/logo.22042016.png") no-repeat 50% 45%;
  }

  @media (max-width: $grid-float-breakpoint-max) {
    .navbar-header {
      background: url("/img/logo.22042016.png") no-repeat 50% 45%;
    }

    .f-view-switcher {
      position: absolute;
      top: 2px;
      left: 15px;
      width: 44px;
      height: 44px;
      margin: auto;
      background-size: contain;
    }
  }
}