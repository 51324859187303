.is-prod-not-visible {
  @include hide-in-production;
}

.is-prod-display-none {
  @include display-none-in-production;
}

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

form#loginForm, form#weatherForm {
  margin: 10px;
  width: 200px;
}

form#loginForm input, form#weatherForm input {
  border: 1px solid;
}

ul.compactList {
  overflow: hidden;
}

ul.compactList li {
  margin: 0 15px;
}

ul.compactList li.divider {
  margin: 0;
}

ul.compactList li select {
  margin-bottom: 15px;
  width: 200px;
}

.modal {
  overflow-y: auto;
}