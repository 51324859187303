// To download more fonts use https://google-webfonts-helper.herokuapp.com/

/* roboto-300 - cyrillic_latin */
@font-face {
  font-family: 'Roboto-Light';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-regular - cyrillic_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* roboto-500 - cyrillic_latin */
@font-face {
  font-family: 'Roboto-Medium';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+ */
  url('/fonts/roboto-v15-cyrillic_latin/roboto-v15-cyrillic_latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}