* {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body, input {
  font-family: $font-regular;
}

body {
  -ms-overflow-style: scrollbar;
}

div, ul, li, a, input {
  box-sizing: border-box;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: none;
  font-family: $font-regular;

  &::-moz-focus-inner {
    border:0;
    padding:0;
  }
}

input {
  border: none;
  outline: none;
  padding: 0;
}