.no-transition {
    transition: none!important;
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;

    &.animated-65 {
        animation-duration: .65s;
    }
    &.animated-30 {
        animation-duration: .3s;
    }
}
//----------------------------------

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

.fadeInLeft {
    animation-name: fadeInLeft;
}

//----------------------------------

@keyframes fadeOutLeft {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
    }
}

.fadeOutLeft {
    animation-name: fadeOutLeft;
}

//----------------------------------

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

//----------------------------------

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.fadeOut {
    animation-name: fadeOut;
}