@mixin uib-arrows ($margin, $value) {
    i {
        display: none;
    }

    width: 28px;
    height: 28px;

    margin-#{$margin}: $value;
    margin-top: 2px;
    margin-bottom: 2px;

    background: #edf5ec url(/img/#{$margin}.22112016.png);
    background-position-y: -1px;

    &:hover {
        opacity: .5;
    }
}

@mixin uib-btn ($w: 28px, $h: 28px) {
    padding: 0;

    border: none;
    border-radius: 0;

    background: #edf5ec;
    color: #4a4a4a;

    width: $w;
    height: $h;

    line-height: $h;
    color: #4a4a4a;

    &:hover {
        background: rgba(0, 0, 0, .1);
    }

    &:disabled {
        opacity: .65;
    }

    &.active {
        background: #4cb141;

        box-shadow: none;
        opacity: 1;

        span {
            color: #fff;
        }
    }

    .text-info {
        color: #4a4a4a;
    }
}

.uib-datepicker-popup {

    padding: 0;

    .uib-datepicker {

        button {
            &.uib-right {
                @include uib-arrows(right, 4px);
            }
            &.uib-left {
                @include uib-arrows(left, 4px);
            }
        }
        .uib {
            &-daypicker {
                td.uib-day {
                    padding: 2px;

                    .btn {
                        @include uib-btn;
                        .text-info {
                            color: #4a4a4a;
                        }
                    }

                }
            }
            &-monthpicker {
                td.uib-month {
                    padding: 2px;

                    .btn {
                        @include uib-btn(79px, 38px);
                        font-size: 12px;
                    }
                }
            }
            &-yearpicker {
                td.uib-year {
                    padding: 2px;

                    .btn {
                        @include uib-btn(48px, 38px);
                        font-size: 12px;
                    }
                }
            }

            &-daypicker,
            &-monthpicker,
            &-yearpicker {
                // Disable focus outline on containers
                &:focus {
                    outline: none;
                }

                // Disable focus outline on all elements inside containers
                * {
                    &:focus {
                        outline: none;
                    }
                }


                th {
                    vertical-align: top;

                    button {
                        border: none;
                    }
                }

                table {
                    display: block;
                    padding: 10px;

                    .uib-title {
                        font-size: 16px;

                        &:hover {
                            background: none;
                        }

                        &:active,
                        &:focus {
                            box-shadow: none;
                            background: none;
                        }
                    }

                }
            }
        }
    }
    .uib-button-bar {
        @include pie-clearfix();

        border-top: 1px solid #ccc;
        padding: 10px;
    }
}