$gradient_background: linear-gradient(to top, #689F38, #9bdd2a);
$margin-left-c-header-farms-item-fields-pointer: 6px;

@mixin c-header-item {
  float: left;
  height: $header-height;
  line-height: $header-height;

  > button {
    display: block;
    height: 100%;
    color: white;
  }

  .uib-dropdown-menu {
    padding: 15px 14px 0 13px;
  }
}

.c-header {
  .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: none;
  }
}

/**
 * Dropdown menu fade effect
 */
.c-header {
  .uib-dropdown-menu {
    opacity: 0;
    transition: opacity .3s ease;
  }

  .dropdown-animated,
  .dropdown-animated-remove.dropdown-animated-remove-active {
    display: block;
  }

  .dropdown-animated,
  .dropdown-animated-add.dropdown-animated-add-active {
    opacity: 1;
  }

  .dropdown-animated-remove.dropdown-animated-remove-active {
    opacity: 0;
  }

  .dropdown-animated-off,
  .dropdown-animated-off-add.dropdown-animated-off-add-active,
  .dropdown-animated-off-remove.dropdown-animated-off-remove-active {
    transition: opacity 0s;
  }
}

.c-header-profile {
  @include c-header-item;

  button {
    height: 48px;
    width: 48px;
    background: #72c368;
    background-size: contain;
    transition: background-color .3s ease;

    &:disabled {
      opacity: 0.5;
    }

    div {
      height: 30px;
      width: 30px;
      left: 9px;
      position: relative;
      border-radius: 15px;
    }
  }

  &.open {
    button {
      background-color: #fff;
    }
  }

  .dropdown-menu {
    width: 207px;
    padding: 7px 0 0;

    @media (min-width: $grid-float-breakpoint) {
      right: 0;
      left: auto;
    }

    li {
      line-height: 38px;

      a {
        display: block;
        text-decoration: none;
      }
    }

    .divider {
      height: 2px;
      background-color: #f4f4f4;
    }

    select {
      width: 100%;
    }
  }
}

.c-header-farms {
  @include c-header-item;

  background: linear-gradient(-180deg, #A0E22A 0%, #679F38 100%);

  .dropdown-menu {
    border-right: none;
    min-width: 207px;
    max-height: 550px;
    overflow: auto;
  }

  &.is-disabled:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    background: rgba(0,0,0,.15);
  }
}

@mixin c-header-farms-toggle-button {
  float: left;
  height: 100%;
  font-size: 14px;

  @media (max-width: $grid-float-breakpoint-max) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.c-header-farms-toggle {
  @include clearfix;

  padding-left: 53px;
  padding-right: 47px;

  &:before{
    content:"";
    position: absolute;
    left: 17px;
    top: 12px;
    width: 26px;
    height: 25px;
    background: url("/img/account_icon.22042016.png");
  }

  &:after{
    content:"";
    position: absolute;
    top: 21px;
    right: 15px;
    width:21px;
    height: 12px;
    background: url("/img/down_icon.22042016.png");
  }

  @media (max-width: $grid-float-breakpoint-max) {
    &.is-farm-field .c-header-farms-toggle--field,
    &.is-farm-field .c-header-farms-toggle--farm {
      // 100 - .c-header-farms-toggle padding
      // 30 - .c-header-farms-pointer width
      // 48 - .c-header-profile width
      // divided by 2 - because we have 2 c-header-farms-toggle-button items
      max-width: calc(50vw - #{(100 + 30 + 48) / 2}px);
    }

    &.is-farm .c-header-farms-toggle--farm,
    &.is-field .c-header-farms-toggle--field {
      @media (max-width: $grid-float-breakpoint-max) {
        // 100 - .c-header-farms-toggle padding
        // 48 - .c-header-profile width
        max-width: calc(100vw - #{100 + 48}px);
      }
    }
  }

  .c-header-farms-toggle--field {
    @include c-header-farms-toggle-button;
    font-family: $font-medium;
    padding: 1px 32px 0 3px;

    @media (max-width: $grid-float-breakpoint-max) {
      padding-right: 0;
    }
  }

  .c-header-farms-toggle--farm {
    @include c-header-farms-toggle-button;
    font-family: $font-light;
    padding: 1px 12px 0 0;

    &.is-selected{
      font-family: $font-medium;
      padding: 1px 20px 0 0;
    }
  }

  &.c-header-farms-toggle-addTask {
    position: relative;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      margin: 0;
      background: rgba(0,0,0,.15);
    }
  }
}

.c-header-farms-pointer {
  float: left;
  overflow: hidden;
  position: relative;
  right: -3px;

  width: 30px;
  height: 48px;

  background-image: linear-gradient(90deg, rgba(0,0,0,0.61) 0%, rgba(0,0,0,0.00) 93%);
  opacity: 0.36;

  &:after{
    content: "";
    position: absolute;
    top: 12px;
    left: -17px;
    width: 23px;
    height: 23px;
    transform: rotate(45deg);
    background: #85C32F;
  }
}

%c-header-farm-item {
  $c-header-farm-item-padding: 10px;

  position: relative;

  display: block;
  border-radius: 99px;
  line-height: 20px;
  overflow: hidden;
  cursor: pointer;

  &.is-active {
    background: #b9e38e;
    padding: 2px 0px 2px 14px;

    &:after {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      background: #67af2e;
      color: white;
      padding: 2px 16px;
      width: 57px;
    }
  }
}

@mixin c-header-farm-item($content) {
  @extend %c-header-farm-item;

  &.is-active {
    &:after {
      content: $content;
      font-family: $font-light;
      font-size: 12px;
      color: #fff;
    }
  }
}

$c-header-farm-item-margin-bottom: 6px;

.c-header-farms-item {
  margin-bottom: $c-header-farm-item-margin-bottom + 1;
  margin-top: 4px;

  &:last-of-type {
    margin-bottom: 20px;
  }

  > span {
    @include c-header-farm-item("Farm");
    padding-left: 14px;
    font-family: $font-light;


    &.is-standalone-field {
      @include c-header-farm-item("Field");
    }
  }
}

.c-header-farms-item-fields {
  margin-top: 25px;
  margin-bottom: -4px;

  > li {
    @include c-header-farm-item("Field");
    margin-top: -16px;
    margin-bottom: $c-header-farm-item-margin-bottom+16;
    margin-left: 21px;
    font-family: $font-light;
    padding: 0 14px;


    &:last-of-type {
      margin-bottom: 13px;
    }

    &:before{
      content:">";
      position: relative;
      top: -1px;

      color:#67AF2E;
      font-weight: bold;
      font-family: $font-regular;
      font-size: 17px;
      padding-right: 6px;
    }
  }
}