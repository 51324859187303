$color-green-header: #4CB141;
$color-grey-calendar: #293028;
$color-blue-panel: #5497a9;
$color-blue-aside-menu: #25606f;
$color-blue-light-aside-menu-button: #aee2f3;
$color-orange-today: #f56423;
$color-blue-aside-info-close: #92b1bd;

$color-white: #fff;

$p: 10px;

$z-1: 1;
$z-2: 2;
$z-3: 3;
$z-4: 4;
$z-5: 5;
$z-6: 6;
$z-110: 110;
$z-big: 200;
$z-max: 1000;

$border-radius: 4px;

$viewport-height: 850px;
$header-height: 48px;
$footer-height: 170px;

$font-light: "Roboto-Light";
$font-medium: "Roboto-Medium";
$font-regular: "Roboto";

$font-form-label-color: #888;

//== Media queries breakpoints
//
// Extra small screen / phone
$screen-xs:                  480px;
$screen-xs-min:              $screen-xs;

// Small screen / tablet
$screen-sm:                  768px;
$screen-sm-min:              $screen-sm;

// Medium screen / desktop
$screen-md:                  992px;
$screen-md-min:              $screen-md;

// Large screen / wide desktop
$screen-lg:                  1200px;
$screen-lg-min:              $screen-lg;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max:              ($screen-sm-min - 1);
$screen-sm-max:              ($screen-md-min - 1);
$screen-md-max:              ($screen-lg-min - 1);

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint:    $screen-sm-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);


.general-form-text-field{
    margin-bottom: 10px;
    border: 1px solid #AAA;
    border-radius: 4px;
    color: #888;
    height: 26px;
    line-height: 26px;
    padding: 2px 7px;
    font-family: $font-regular;
    font-size: 14px;
    margin-top:5px;
    box-sizing:content-box;
}
.general-form-button{
    display: block;
    font-size: 15px;
    font-family: $font-medium;
    background: rgba(0, 0, 0, 0) linear-gradient(-180deg, #a0e22a 0%, #679f38 100%) repeat scroll 0 0;
    border-radius: 4px;
    color: white;
    padding:8px 17px;
    margin-top: 10px;
    margin-bottom: 5px;	
}