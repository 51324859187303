//vertical align by center using table
.c-va-center-container {
  display: table;
  width: 100%;
  height: 100%;
}

.c-va-center-item {
  display: table-cell;
  vertical-align: middle;
}