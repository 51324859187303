@mixin height-and-line-height($height) {
  height: $height;
  line-height: $height;
}

@mixin min-height-and-line-height($height) {
  min-height: $height;
  line-height: $height;
}

@mixin width-and-height($dimension) {
  width: $dimension;
  height: $dimension;
}

@mixin padding-top-bottom($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin inline-margin-right($margin: 0) {
  //https://css-tricks.com/fighting-the-space-between-inline-block-elements/
  margin-right: ($margin - 4px);
}

@mixin table-cell-vertical-alight($va: middle) {
  display: table-cell;
  vertical-align: $va;
}

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@mixin hide-in-production {
  .is-env-prod & {
    visibility: hidden !important;
  }
}

@mixin display-none-in-production {
  .is-env-prod & {
    display: none !important;
  }
}

@mixin is-touch {
  .touch & {
    @content;
  }
}

@mixin is-no-touch {
  .no-touch & {
    @content;
  }
}

@mixin is-no-touch-hover {
  .no-touch & {
    &:hover {
      @content;
    }
  }
}

@mixin isolate-paint-rectangle {
  -webkit-transform: translate3d(0,0,0);
}

@mixin transparent-gif-background {
  //http://stackoverflow.com/questions/6914822/absolute-positioned-anchor-tag-with-no-text-not-clickable-in-ie
  background-image: url('data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICR‌​AEAOw==')
}

%loading-img-width-placeholder {
  //http://fa2png.io/r/font-awesome/picture-o/?color=464646&margin=0&size=56
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADgAAAA4CAMAAACfWMssAAAAz1BMVEUAAABGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkZGRkaZ2u+HAAAARHRSTlMAAQIDBAUGBwgJCgsMDQ4RFxghIzU6Oz0+P0BQUVtrbXV3fH6FiY+XoKW8w8XHyMzOz9HT1dfZ2tze4OLk7fP19/n7/djxqTwAAAFVSURBVEjH7dZrU4JAFAbgIy0KQmZlXkoriu55SUwyLU3f//+b+oBgXJbZ3bFvnI/MPAPz7p7DISoqr8yLG1eqrs80IqLGGtLlHxLVoVK+Rg9KEE36Ap5LcqFYABwC4MrGCcDdDzwoqUD7boXNY10anqyCuDqS0PgOgz6Wg5fRCT3JwfHubFMJMT0HLneQJd3LQOfDfuTWiTeyV2Coc2Evgvdxpw8AYFTmQbbYup9a3A2Dx+MK7xyPArk8jbnyKPwQr8K7OazXX3pXZty97TJ7N8QvecX724JTQxQak0Tbm2LQmCbbflbNgVo0/Pz0wPi0uLA12aZX/cgaNXObA1thetYse0gtWCZshblbc954q2XBdpi7vYAMbEe5810W7AhN4jQUc2l4DjUo6lIQBeTDrmixvf4fpaDCDlAF4KhvHcp7DjU2apsVkdm9ldvlnKZWbMD/U7+8YGsl+7wGHAAAAABJRU5ErkJggg==)
}